import React from 'react';
import PropTypes from 'prop-types';

import IconSlider from './icon-slider';

import useCustomers from '../../hooks/use-customers';
import useBlockCustomers from '../../hooks/use-block-customers';

// import styles from './customers.module.scss';

const Customers = ({ slidesToShow }) => {
	const { title } = useBlockCustomers();
	const customers = useCustomers();

	return <IconSlider title={title} items={customers} slidesToShow={slidesToShow} />;
};

Customers.defaultProps = {
	slidesToShow: 2
};

Customers.propTypes = {
	slidesToShow: PropTypes.number
};

export default Customers;
